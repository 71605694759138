var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
            text: _vm.$t('name'),
            id: 'label_group_user_name',
            class: '',
            style: 'margin-left:35px;'
        },"datainput":{
            id: 'input_group_user_name',
            name: 'user[first_name]',
            type: 'text'
        }}}),_c('GhInputTextField',{attrs:{"datalabel":{
            text: _vm.$t('surname'),
            id: 'label_group_user_last_name',
            class: '',
            style: 'margin-left:66px;'
        },"datainput":{
            class: '',
            id: 'input_group_user_last_name',
            name: 'user[last_name]',
            type: 'text'
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }